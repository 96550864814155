
	//  -------------------------------------------------
	//  TILES Utility


		

		$(".match-height").each(function(){
			// Get highest parent from element (of type .row)
			var highestParent = $(this).parents(".row").last(); 
			// Get height of reference parent
			var refHeight = highestParent.height();
			// Give determined height to element
			$(this).height(refHeight);
		});

	//  -------------------------------------------------
	//  MAP - Using provided address, or coords 
	

	// Locate map center point using provided address


	function locate_using_coords(getLat, getLng, map){ 
	 	// If lat lng are provided
	 	var lat_lng = { lat: getLat, lng: getLng};	
	 	// Recenter map using coords
	 	map.setCenter(lat_lng);
	 	// Place a marker using coords
	    var marker = new google.maps.Marker({
	        map: map,
	        position: lat_lng
	    });
	} // locate_using_coords()


	function locate_using_address(address,resultsMap,dataLat,dataLng,currentMapWrap, markerBool) {

		var geocoder = new google.maps.Geocoder();
	  	geocoder.geocode({'address': address}, function(results, status) {
	    
	    // If geocoding successfull
	    if (status === google.maps.GeocoderStatus.OK) {
	      resultsMap.setCenter(results[0].geometry.location);
	      if (markerBool == 1) {
	      	 var marker = new google.maps.Marker({
		        map: resultsMap,
		        position: results[0].geometry.location
		      });
		   };
	     
	    } // geocoding successful

	    // If geocoding unsuccessful from provided address 
	    // (ex: no address provided), Use provided lat-lng instead.
	    else { 
	      	console.log(currentMapWrap.attr("class")+" -> Geocoding unsuccessful ("+address+"); " + status); 
	      	//currentMapWrap.hide();
	   		if(dataLat != "" && dataLng != ""){
		 		locate_using_coords(dataLat, dataLng, resultsMap); 
		 	} 
		 	else{
		 		currentMapWrap.hide();
		 	}
	    } // If geocoding unsuccessful

	  }); 

	} // locate_using_address

	function initMap() {

		// Witness pointer
		var index = 0; 

		var colors = [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}];
	

		// If at least one map on page

		if ($(".js-map").length) { 

			// Browse each map info and create a map
		 	$(".js-map").each(function(){

		 		// Parameters

		 		var zoom = $(this).data("zoom"); 
		 		if (zoom == undefined || zoom == "") { zoom = 10; };

		 		var colored = $(this).data("colored"); 
		 		if (colored == undefined || colored == "" || colored == 0) { colored = 0; } 
		 		else{ colored = colors;};

		 		var marker = $(this).data("marker"); 
		 		if (marker == undefined || marker == "") { marker = 0; };

		 		var ui = $(this).data("ui"); 
		 		if (ui == undefined || ui == "" || ui == 0) { ui = true; }
		 		else{ ui = false; }


		 		// Map declaration

		 		var map = new google.maps.Map($(".js-map")[index], {
				    zoom: zoom,
				    center: {lat: 0, lng: 0},
				    scrollwheel: false, 
				    styles : colored,
				    disableDefaultUI: ui
				});

		 		// If address provided :  

		  		var address = $(this).data("addr"); 
		  		var dataLat = "";
			 	var dataLng = "";

			 	if ($(this).data("lat") != "" && $(this).data("lng")) {
			 		dataLat = $(this).data("lat");
			 		dataLng = $(this).data("lng");
			 	}

			 	// // Initiate geocoding from map's data address, if provided
			 	if (address != "") {
			 		locate_using_address(address, map, dataLat, dataLng, $(this), marker); 
			 	} 
			 	// If not, use coordinates
			 	else if(dataLat != "" && dataLng != ""){
			 		locate_using_coords(dataLat, dataLng, map); 
			 	} else{
			 		console.log($(this).attr('class')+" -> "+$(this).index()+" -> No data involved.");
			 		$(this).hide();
			 	}
			 	
			 	index++;

		 	});

	 	} // If js-map contains something
 		

 	} // initMap

	
	//  END MAP - Using provided address, or coords 
	//  -------------------------------------------------

	//  -------------------------------------------------
	//  ACCORDIONs - Slide down - up

		var accordion = $(".accordion");
		var accordion_length = accordion.length;

		if (accordion_length > 0) {

			var elem = $(".element");
			var title = $(".title");
			var content = $(".content");
			
			elem.click(function(){
				$(this).toggleClass("active");
				$(this).find(content).slideToggle(200);
			});

		};

	//  -------------------------------------------------
	//  TOGGLE Utility

	$("[data-toggle]").click(function(){
		var target = $(this).data("toggle");
		$("[data-elem='"+target+"']").slideToggle();
	});
	
	$("menu nav .mobile-actions [data-toggle='menu']").click(function(){
		$(this).parents("menu").toggleClass("active");
	});


	//  -------------------------------------------------
	//  TABS Utility


	$('.nav-tabs a').click(function (e) {
	  e.preventDefault();
	  $(this).tab('show');
	});


	$(window).on('load', function(){
	/* ////////////////////////////////////////
	//
	// Init
	//
	/////////////////////////////////////// */

	//du code soit pour le loading mask ou pour initialiser le loading du site

		}).scroll(function(){
			scrollContent();
		});

		/* ////////////////////////////////////////
			//
			// Scroll content
			//
			/////////////////////////////////////// */
		function scrollContent(){
			var totalScroll = $(document).height() - $(window).height();

			//if(browserMobile){
			newScroll = $(window).scrollTop();
			// } else {
			// 	if(whichBrs() == 'Safari' || whichBrs() == 'Chrome'){
			// 		newScroll = $('body').scrollTop();
			// 	} else {
			// 		newScroll = $('html,body').scrollTop();
			// 	}
			// }

			currentScroll = newScroll;

			/* To-load
			================================================== */
			$('.to-load').each(function(){
				var object = $(this);		
					
				if(newScroll + $(window).height() * 0.85 > $(this).offset().top){
					object.removeClass('no-anim');
					object.addClass('loaded');
				} else if(newScroll + $(window).height() < $(this).offset().top) {
					object.addClass('no-anim');
					object.removeClass('loaded');
				}
					
			});
			
		}

		setTimeout(function(){
			scrollContent();
		}, 1000);

		

			// $('#background > div > img').each(function(){
			// 	var posLeft = ($(this).width() - $(this).parent().width()) / 2;
			// 	$(this).css({'left': - posLeft});
			// });

			scrollContent();

		

